<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      fill="#58585a"
      d="m12.37,15l5.76-5.84s.02-.02.03-.03c.53-.55.52-1.43-.04-1.97s-1.43-.52-1.97.04l-6.78,6.8c-.5.55-.48,1.39.04,1.92l6.88,6.9c.54.54,1.43.54,1.97,0,.54-.54.54-1.43,0-1.97l-5.91-5.85Z"
    />
    <path
      fill="#fff"
      d="m15,0C6.72,0,0,6.72,0,15s6.72,15,15,15c8.28,0,15-6.71,15-15h0C30,6.72,23.28,0,15,0Zm3.27,22.82c-.54.54-1.43.54-1.97,0l-6.88-6.9c-.52-.53-.54-1.37-.04-1.92l6.78-6.8c.53-.55,1.41-.57,1.97-.04s.57,1.41.04,1.97c-.01.01-.02.02-.03.03l-5.76,5.84,5.91,5.85c.54.54.54,1.43,0,1.97Z"
    />
  </svg>
</template>
